import { useEffect, useMemo, useState } from 'react';

import { useClearAction, useDeleteOrgUser, useEditOrgUser } from 'api/apiMetaThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';
import {
  getFeatureOptions,
  getLicenseSelectionOptions,
  getTeamOptions,
} from 'utils/selectorOptions';
import { AddUsersToOrgHeader } from 'components/headers/AddUsersToOrgHeader';
import { OrgLicenses } from 'components/organization/OrgLicenses';
import { Modal } from 'components/modals/Modal';
import { Box, Button, Divider } from '@mui/material';
import { Selector } from 'components/Selector';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { AddOrgTeamForm } from 'components/AddOrgTeamForm';
import { addTeam, deleteTeam } from 'api/apiThunks';
import { NoLicenseSku } from 'constant';
import { usePrivilegeSelector } from 'utils/usePrivilegeSelector';
import { Privilege } from 'types/enums';
import { Feature, FeatureAccess } from 'types/server/common/enums';

const enum Field {
  team = 'Team',
  isOrgAdmin = 'Org Admin?',
  onlySSO = 'Only Allow SSO?',
  license = 'License',
  features = 'Features',
}

/**
 * The organization users' view
 */
export const Users = (): JSX.Element => {
  const [{ actionType, actionUserId, editDef }, setAction] = useState<{
    actionType?: 'delete' | 'edit';
    actionUserId?: string;
    editDef?: EditOrgUserDef;
  }>({});
  const [salesforceOrdersModalOpen, setSalesforceOrdersModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState('all');
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [operationType, setOperationType] = useState<OrgOpName>();

  const org = useAppSelector(selectDerivedOrganization);
  const adminPrivilege = usePrivilegeSelector();
  const viewOperations = getOperations(org, 'users');

  const [addUsersOperations, setAddUsersOperations] = useState<Operation[]>([]);
  const [operationMessage, setOperationMessage] = useState('');
  const teamOperations = useMemo(() => {
    return operationType ? getOperations(org, operationType) : [];
  }, [org, operationType]);
  const contentOperations = [
    ...teamOperations,
    ...addUsersOperations,
    ...useEditOrgUser(org, actionUserId, editDef, actionType === 'edit'),
    ...useDeleteOrgUser(org, actionUserId, actionType === 'delete'),
  ];
  useClearAction(setAction, !!actionType && !contentOperations.length);

  const { orgId, users = [], licenseMap = {}, sfOrders = [], licenses = [], teams = [] } = org;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!operationType && teamOperations[0]?.status === 'succeeded') {
      setOperationType(undefined);
    }
  }, [operationType, teamOperations]);

  const orgUsers = useMemo(() => {
    const licensedUsers = users.filter((user) => !!licenseMap[user.userId]);
    const usersWithSku = users.filter(
      (user) =>
        !licenseMap[user.userId] && !!user.currentSkuId && user.currentSkuId !== NoLicenseSku.id
    );
    const usersWithNoLicenseSku = users.filter(
      (user) =>
        !licenseMap[user.userId] && !!user.currentSkuId && user.currentSkuId === NoLicenseSku.id
    );
    const usersWithNoSku = users.filter((user) => !licenseMap[user.userId] && !user.currentSkuId);
    return [...licensedUsers, ...usersWithSku, ...usersWithNoLicenseSku, ...usersWithNoSku];
  }, [users, licenseMap]);

  const filteredUsers = useMemo(() => {
    return selectedTeam !== 'all'
      ? orgUsers.filter((user) =>
          selectedTeam === 'not_on_a_team'
            ? !user.assignedTeam?.id
            : user.assignedTeam?.id === selectedTeam
        )
      : orgUsers;
  }, [orgUsers, selectedTeam]);

  if (!org.orgId) return <></>;

  const actionUser = users.find((user) => user.userId === actionUserId);
  const actionMessage = actionType === 'edit' ? 'Updating user' : 'Deleting user';
  const licenseOptions = getLicenseSelectionOptions(licenses);
  const allTeamOptions = getTeamOptions(teams, true);
  const teamOptions = allTeamOptions.filter((x) => x.value !== 'all');
  let featureOptions = getFeatureOptions(adminPrivilege === Privilege.Intermediate, true, true);

  if (adminPrivilege !== Privilege.All) {
    featureOptions = featureOptions.filter((x) => x.value !== Feature.Impersonate);
  }
  const orgFeatures: IOrgFeature[] = org.org?.features || [];
  const filteredOrgFeatureIds = new Set(
    orgFeatures
      .filter((feature) => feature.fAccess !== FeatureAccess.InheritSku)
      .map((feature) => feature.id)
  );

  const disabledFeatureIds = featureOptions
    .filter((option) => !filteredOrgFeatureIds.has(option.value))
    .map((option) => option.value);

  const showNewApiColumns = users.some((user) => user.dateCreated || user.lastLoginTS);
  const isProcessing = !!actionType || !!addUsersOperations.length || !!operationType;
  const disableDelete =
    ['all', 'not_on_a_team'].includes(selectedTeam) || isProcessing || !selectedTeam;
  let contentMessage = '';
  if (operationType) {
    contentMessage = operationType === 'addTeam' ? 'Adding team' : 'Deleting team';
  } else {
    contentMessage = addUsersOperations.length
      ? operationMessage
      : `${actionMessage} ${actionUser?.fullName || actionUser?.emailAddress}`;
  }

  const handleOnAdd = (formInput: Partial<IOrgTeam>) => {
    setOperationType('addTeam');
    dispatch(addTeam({ orgId, key: '', value: formInput }));
  };

  const handleOnDelete = () => {
    setDeleteConfirmModalOpen(false);
    setOperationType('deleteTeam');
    dispatch(deleteTeam({ orgId, value: selectedTeam }));
  };

  return (
    <ViewFrame
      viewLoader={{
        message: 'Loading users for the organization. This might take a minute.',
        viewOperations,
      }}
      header={
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: 2, mb: 2 }}
        >
          <Selector
            label="Team"
            options={allTeamOptions}
            value={selectedTeam}
            setValue={(val) => setSelectedTeam(val as string)}
            isDisabled={isProcessing}
            sx={{ minWidth: 200 }}
          />
          <Button
            variant="contained"
            onClick={() => setDeleteConfirmModalOpen(true)}
            sx={{ ml: 1 }}
            disabled={disableDelete}
          >
            Delete
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2 }} />
          <AddOrgTeamForm
            onSubmit={handleOnAdd}
            orgTeamNames={teamOptions.map((x) => x.text?.toLowerCase())}
            disabled={isProcessing}
          />
          <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2 }} />
          <AddUsersToOrgHeader
            orgId={org.orgId}
            orgName={org.org?.name}
            currentOperation={operationMessage}
            setOperation={(message, ops) => {
              // If a single successful operation, then translate to [] (no operation)
              ops = ops.length === 1 && ops[0]?.status === 'succeeded' ? [] : ops;
              setOperationMessage(message);
              setAddUsersOperations(ops);
            }}
          />
          {!!sfOrders.length && !!licenses.length && (
            <>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 2, mr: 2 }} />
              <Button
                variant="contained"
                onClick={() => setSalesforceOrdersModalOpen(true)}
                sx={{ height: 'fit-content' }}
              >
                Salesforce Orders
              </Button>
            </>
          )}
        </Box>
      }
      contentLoader={{
        message: contentMessage,
        contentOperations,
        forceClose: !isProcessing,
        onClose: () => {
          setAction({});
          setOperationMessage('');
          setAddUsersOperations([]);
        },
      }}
    >
      <StandardGrid
        dataSet={filteredUsers}
        tipModel="OrgUser"
        getRowId={(x) => x.userId}
        disabledOptionIds={disabledFeatureIds}
        getOpenAction={(x) =>
          x.newUserId ? { email: x.emailAddress, type: 'NewUser' } : { id: x.userId, type: 'User' }
        }
        filterPlaceholder="Filter the displayed users..."
        cols={[
          {
            name: 'Name',
            valueProperty: 'fullName',
          },
          {
            name: 'Email',
            valueProperty: 'emailAddress',
            type: 'email',
          },
          {
            name: Field.team,
            valueProperty: 'assignedTeam',
            getValue: (x) => x.assignedTeam?.id ?? 'not_on_a_team',
            editOptions: teamOptions,
          },
          {
            name: Field.isOrgAdmin,
            valueProperty: 'isOrgAdmin',
            type: 'boolean',
            isEditable: true,
            relativeWidth: 0.5,
          },
          {
            name: Field.onlySSO,
            valueProperty: 'onlyAllowSSOLogins',
            type: 'boolean',
            isEditable: true,
            relativeWidth: 0.5,
          },
          {
            name: Field.features,
            valueProperty: 'features',
            editOptions: featureOptions,
            type: 'multiSelect',
            disabled: adminPrivilege !== Privilege.All,
          },
          {
            name: Field.license,
            getValue: (x) => {
              const license = licenseMap[x.userId];
              if (license) {
                return license.orderItemId;
              } else {
                return x.currentSkuName ? `sku: ${x.currentSkuName}` : '';
              }
            },
            editOptions: licenseOptions,
          },
          {
            name: 'Sku Expiration',
            valueProperty: 'currentSkuExpirationTS',
          },
          {
            name: 'Date Created',
            description:
              'The date the user was created (may be before they were added to the organization)',
            valueProperty: 'dateCreated',
            type: 'date',
            hide: !showNewApiColumns,
          },
          {
            name: 'Last Login',
            description: 'The date the user last logged in (not last use)',
            valueProperty: 'lastLoginTS',
            type: 'date',
            hide: !showNewApiColumns,
          },
          {
            name: 'Activation Link',
            valueProperty: 'fullActivationLink',
          },
        ]}
        getDeleteAction={{
          action: (id) => {
            setAction({
              actionType: 'delete',
              actionUserId: id as string,
            });
          },
          description: 'Permanently delete the user and all associated data',
          getDeleteInfo: (x) => ({
            value: x.userId,
            confirmName: x.fullName || x.emailAddress || '',
          }),
        }}
        getEditAction={(oldRow, newRow) => {
          const editDef: EditOrgUserDef = {};
          const getEdit = (x: Field) => (oldRow[x] !== newRow[x] ? newRow[x] : undefined);

          const orderItemId = getEdit(Field.license) as string;
          if (
            orderItemId &&
            (orderItemId === 'none' ||
              licenses.find((license) => license.orderItemId === orderItemId))
          ) {
            editDef.orderItemId = orderItemId;
          }

          const teamId = getEdit(Field.team) as string;
          if (teamId && (teamId === 'not_on_a_team' || teams.find((team) => team.id === teamId))) {
            editDef.teamId = teamId;
          }

          const isOrgAdmin = getEdit(Field.isOrgAdmin) as boolean;
          if (isOrgAdmin !== undefined) {
            editDef.isOrgAdmin = isOrgAdmin;
          }

          const onlySSO = getEdit(Field.onlySSO) as boolean;
          if (onlySSO !== undefined) {
            editDef.onlyAllowSSOLogins = onlySSO;
          }

          const oldFeatures = (oldRow[Field.features] as string[]) ?? [];
          const newFeatures = (newRow[Field.features] as string[]) ?? [];
          const toRemove = oldFeatures.filter((x) => !newFeatures.includes(x));
          const toAdd = newFeatures.filter((x) => !oldFeatures.includes(x));
          if (toRemove !== undefined) {
            editDef.featureRemove = toRemove;
          }
          if (toAdd !== undefined) {
            editDef.featureAdd = toAdd;
          }

          if (Object.keys(editDef).length) {
            setAction({
              actionType: 'edit',
              actionUserId: newRow['id'] as string,
              editDef,
            });
          }
        }}
      />
      <Modal
        title="Salesforce Order Information"
        open={salesforceOrdersModalOpen}
        onClose={() => setSalesforceOrdersModalOpen(false)}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'md',
        }}
        buttonText="Close"
      >
        <OrgLicenses sfOrders={sfOrders} licenses={licenses} />
      </Modal>
      <ConfirmModal
        open={deleteConfirmModalOpen}
        prompt="Permanently delete the selected team?"
        onClose={() => setDeleteConfirmModalOpen(false)}
        onAccept={handleOnDelete}
      />
    </ViewFrame>
  );
};
